import React, { useState } from "react";
import "./Payment.css";
import PaymentCard from "./components/paymentCard/PaymentCard";
import PaymentSection from "./components/paymentSection/PaymentSection";
import CustomDialog from "../../../components/customDialog/CustomDialog";
import ModalHeader from "./components/modalHeader/ModalHeader";
import ModalBtn from "./components/modalBtn/ModalBtn";
// import ModalLaterBtn from "./components/modalLaterBtn/ModalLaterBtn";
// import AddMoneyNew from "./components/addMoney/AddMoneyNew";
// import AddBankCard from "./components/addMoney/AddBankCard";
// import AddBankCardOTP from "./components/addMoney/AddBankCardOTP";
// import AddMoneyPassword from "./components/addMoney/AddMoneyPassword";
// import AddMoneyAllCards from "./components/addMoney/AddMoneyAllCards";
// import Withdrawal from "./components/withdrawal/Withdrawal";
// import WithdrawalOTP from "./components/withdrawal/WithdrawalOTP";
// import WithdrawalSuccess from "./components/withdrawal/WithdrawalSuccess";
import { paymentHistory } from "../../../assets/userData";
import CustomAutoComplete from "../../../components/customAutoComplete/CustomAutoComplete";
import ProfileEditInput from "../../../components/profileEditInput/ProfileEditInput";
import usePayments from "../../../hook/usePayments";

const Payment = () => {
  const [showSetup, setShowSetup] = useState(false);
  // const [showAddMoney, setShowAddMoney] = useState(false);
  // const [showWithdraw, setShowWithdraw] = useState(false);
  // const [stepAddMoney, setStepAddMoney] = useState(1);
  // const [stepWithdraw, setStepWithdraw] = useState(1);

  const handleClose = () => {
    //setShowAddMoney(false);
    setShowSetup(false);
    // setShowWithdraw(false);
    // setStepAddMoney(1);
    // setStepWithdraw(1);
  };

  // const addMoneyModal = () => {
  //   switch (stepAddMoney) {
  //     case 1:
  //       return (
  //         <AddMoneyNew
  //           handleClose={handleClose}
  //           setStepAddMoney={setStepAddMoney}
  //         />
  //       );
  //     case 2:
  //       return (
  //         <AddBankCard
  //           handleClose={handleClose}
  //           setStepAddMoney={setStepAddMoney}
  //         />
  //       );
  //     case 3:
  //       return (
  //         <AddBankCardOTP
  //           handleClose={handleClose}
  //           setStepAddMoney={setStepAddMoney}
  //         />
  //       );
  //     case 4:
  //       return (
  //         <AddMoneyPassword
  //           handleClose={handleClose}
  //           setStepAddMoney={setStepAddMoney}
  //         />
  //       );
  //     case 5:
  //       return (
  //         <AddMoneyAllCards
  //           handleClose={handleClose}
  //           setStepAddMoney={setStepAddMoney}
  //         />
  //       );
  //   }
  // };

  // const withdrawModal = () => {
  //   switch (stepWithdraw) {
  //     case 1:
  //       return (
  //         <Withdrawal
  //           handleClose={handleClose}
  //           setStepWithdraw={setStepWithdraw}
  //         />
  //       );
  //     case 2:
  //       return (
  //         <WithdrawalOTP
  //           handleClose={handleClose}
  //           setStepWithdraw={setStepWithdraw}
  //         />
  //       );
  //     case 3:
  //       return (
  //         <WithdrawalSuccess
  //           handleClose={handleClose}
  //           setStepWithdraw={setStepWithdraw}
  //         />
  //       );
  //   }
  // };

  return (
    <div className="payment-container">
      <h2>Payment</h2>

      <div className="payment-content">
        <div className="box-container">
          <PaymentCard label="Total Payout" amount={"NGN 540,000"} />
          <PaymentCard label="Pending Payment" amount={"NGN 24,000"} />
        </div>

        <div>
          {/* <PaymentSection
            subTitle="where your earning would be credited into"
            btnTitle={"Add account"}
            showmodal={() => setShowSetup(true)}
          /> */}

          <PaymentSection
            accNo={9876543210}
            subTitle="Kuda bank"
            btnTitle="Edit Account"
            showmodal={() => setShowSetup(true)}
          />
        </div>

        <div className="payment-bottom">
          <h4>Payment history</h4>

          <div className="table-container">
            <table className="table">
              <thead>
                <tr>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Method</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {paymentHistory.map((i, index) => (
                  <tr key={index}>
                    <td>NGN {i.amount}</td>
                    <td>{i.date}</td>
                    <td>{i.method}</td>
                    <td>
                      <div className="status">
                        <span
                          className={
                            i.status === "Pending"
                              ? "pend"
                              : i.status === "Successful"
                              ? "succ"
                              : null
                          }
                        >
                          {i.status}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mobile-table-con">
            {paymentHistory.map((i, index) => (
              <div key={index} className="mobile-single-table">
                <div className="cols-mobile">
                  <h6>Amount</h6>
                  <p>NGN {i.amount}</p>
                </div>
                <div className="cols-mobile">
                  <h6>Date</h6>
                  <p>{i.date}</p>
                </div>
                <div className="cols-mobile">
                  <h6>Method</h6>
                  <p>{i.method}</p>
                </div>
                <div className="cols-mobile">
                  <h6>Status</h6>
                  <div className="status">
                    <span
                      className={
                        i.status === "Pending"
                          ? "pend"
                          : i.status === "Successful"
                          ? "succ"
                          : null
                      }
                    >
                      {i.status}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="payment-board">
            <p>No transactions recorded yet</p>
          </div> */}
        </div>

        <CustomDialog open={showSetup}>
          <BankAccount close={handleClose} />
        </CustomDialog>

        {/* <CustomDialog open={showAddMoney}>{addMoneyModal()}</CustomDialog>
        <CustomDialog open={showWithdraw}>{withdrawModal()}</CustomDialog> */}
      </div>
    </div>
  );
};

export default Payment;

const BankAccount = ({ close }) => {
  const { banks } = usePayments();

  return (
    <div className="modal-container-inner">
      <ModalHeader title="Bank Account" onClose={close} />

      <div className="warning-acc">
        <p>
          Please kindly ensure that your account details revert with the name of
          your here on your profile.
        </p>
      </div>

      <div className="input-modal-cover">
        <div className="auto-complete-con">
          <CustomAutoComplete
            isClearable={false}
            label="Bank name"
            placeholder="Bank name"
            customLabelStyles={{
              color: "#021424",
              fontWeight: 600,
            }}
            customStyles={{
              valueContainer: {
                paddingTop: "11px",
                paddingBottom: "11px",
              },
            }}
            options={banks}
            // initOption={countrySelectedOption}
            // onChange={changeCountryHandler}
          />
        </div>
        <ProfileEditInput
          label="Account number"
          placeholder="Account number"
          widthCon="100%"
          width="100%"
          customLabelStyles={{
            color: "#021424",
            fontWeight: 600,
          }}
          customInputStyles={{
            height: 55,
            fontSize: 16,
            fontWeight: 500,
          }}
        />
        <ProfileEditInput
          label="Account name"
          placeholder="Account name"
          widthCon="100%"
          width="100%"
          customLabelStyles={{
            color: "#021424",
            fontWeight: 600,
          }}
          customInputStyles={{
            height: 55,
            fontSize: 16,
            fontWeight: 500,
          }}
        />
      </div>

      <div className="footer-btns-bg">
        <ModalBtn title="Update" />
      </div>
    </div>
  );
};
