import { api } from "../BaseConfig";

export const dashboardSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getJobsAlert: builder.query({
      query: () => ({
        url: `/individual/job-alert`,
        method: "GET",
      }),
    }),
    getDashJobApplication: builder.query({
      query: () => ({
        url: `/individual/dashboard-application`,
        method: "GET",
      }),
    }),
    getApplicationCount: builder.query({
      query: () => ({
        url: `/individual/application-count`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetJobsAlertQuery,
  useGetDashJobApplicationQuery,
  useGetApplicationCountQuery,
} = dashboardSlice;
