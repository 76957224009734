import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { errorProps, successProps } from "../utils";
import { useDispatch } from "react-redux";
import { register } from "../store/slices/user";
import { useLazyAuthQuery, useLoginUserMutation } from "../store/query/auth";
import { useCallback } from "react";

const useLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginUser, { isLoading }] = useLoginUserMutation();
  const [auth, { isLoading: isAuth }] = useLazyAuthQuery();

  const loading = isLoading || isAuth;

  const getUserDetails = async (body, nav) => {
    try {
      const res = await auth(body);
      if ("data" in res) {
        const { data } = res;
        if (data.status === true) {
          dispatch(register({ token: body.token, user: data.data }));
          navigate(nav);
        }
      }
    } catch (error) {
      toast.error(error.message, errorProps);
    }
  };

  const handleSubmit = useCallback(async (values, nav) => {
    const res = await loginUser(values);
    if ("data" in res) {
      const { data } = res;
      if (data.status === true) {
        if (!data?.account_data?.role) {
          dispatch(register({ token: data.token, user: null }));
          toast.success("Successfully Login", successProps);
          const body = {
            token: data?.token,
            acc_type: data?.account_data?.account_type,
          };
          getUserDetails(body, nav);
        } else {
          toast.error(
            "Log in as an individual/organisation and not an admin",
            errorProps
          );
        }
      }
    } else if ("error" in res) {
      const { error } = res;

      if (error?.status === "FETCH_ERROR") {
        toast.error("Network Error", errorProps);
      }
      toast.error(error?.data?.message, errorProps);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Provide vaild email address")
        .required("Required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
          "Password must contain at least one lowercase letter, one uppercase letter, and one number"
        )
        .required("Required"),
    }),
    onSubmit: (values) => {
      handleSubmit(values, `/dashboard`);
    },
  });

  return {
    ...formik,
    loading,
    onSubmit: handleSubmit,
    getUser: getUserDetails,
  };
};

export default useLogin;
