import React, { useState } from "react";
import "./JobItem.css";
import { CiLocationOn } from "react-icons/ci";
import CustomButton from "../customButton/CustomButton";
import { config } from "../../config";
import { useTypedSelector } from "../../hook/useTypedSelector";
import { _currency_format } from "../../utils";
import { useGetApplicantsSingleJobQuery } from "../../store/query/applicants";

const JobItem = ({
  id = null,
  title,
  rate,
  jobDesc,
  jobLoc,
  jobImg,
  jobOrg,
  applicants,
  view,
  count = 100,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { user } = useTypedSelector((store) => store.appUser);
  const { data } = useGetApplicantsSingleJobQuery({
    id,
    type: user?.account_type,
  });

  const logo =
    jobImg?.length > 0
      ? `${config.getPublicImages}/${jobImg}`
      : `https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg`;

  return (
    <>
      <div
        className="job-result-box"
        onMouseEnter={() => (view && applicants ? setIsHovered(true) : null)}
        onMouseLeave={() => (view && applicants ? setIsHovered(false) : null)}
      >
        <div className="job-box-top">
          <h5>{title.length > 12 ? title.slice(0, 15) + "..." : title}</h5>
          <span className="job-rate">
            {_currency_format(Number(rate), "NGN")}/hr
          </span>
        </div>
        <div className="job-desc-box">
          <p className="job-description">
            {jobDesc.length > count ? jobDesc.slice(0, count) + "..." : jobDesc}
          </p>
        </div>
        <div className="job-result-bottom">
          <div className="job-result-image">
            <img src={logo} alt="logo" />
          </div>
          <div className="job-result-others">
            <h6>{jobOrg}</h6>
            <div className="job-location">
              <CiLocationOn className="job-location-icon" />
              <span className="job-location-text">{jobLoc}</span>
            </div>
          </div>
        </div>

        {view && applicants && isHovered && (
          <div className="hover-state">
            <CustomButton
              onClick={view}
              title="VIEW"
              customStyles={{
                width: "55%",
                borderRadius: "5px",
                fontSize: "13px",
                fontWeight: 800,
                letterSpacing: "1px",
              }}
            />
            <CustomButton
              onClick={applicants}
              title={`APPLICANTS (${
                data?.data?.allAppliedApplicantCount ?? 0
              })`}
              customStyles={{
                width: "55%",
                borderRadius: "5px",
                fontSize: "13px",
                fontWeight: 800,
                letterSpacing: "1px",
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default JobItem;
