import { api } from "../BaseConfig";

export const utilitySlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getDivisions: builder.query({
      query: (params) => ({
        url: `/view-divisions`,
        method: "GET",
        params,
      }),
    }),
    getOrgRoles: builder.query({
      query: (params) => ({
        url: `/organisation/view-organisation-role`,
        method: "GET",
        params,
      }),
    }),
    getSkills: builder.query({
      query: (params) => ({
        url: `/${params.type}/view-skills`,
        method: "GET",
        params,
      }),
    }),
  }),
});

export const { useGetDivisionsQuery, useGetOrgRolesQuery, useGetSkillsQuery } = utilitySlice;
