import { useMemo } from "react";
import { useGetSkillsQuery } from "../store/query/utility";
import { useTypedSelector } from "./useTypedSelector";

const useSkills = () => {
  const { user } = useTypedSelector((store) => store.appUser);
  const { data: skillsList } = useGetSkillsQuery({ type: user?.account_type });

  const skills = useMemo(
    () =>
      skillsList?.data?.allSkill?.map((i) => {
        return { label: i.name, value: i.id };
      }),
    [skillsList?.data]
  );

  return { skills };
};

export default useSkills;
