import { useMemo } from "react";
import { useGetBanksQuery } from "../store/query/payments";

const usePayments = () => {
  const { data: banksData } = useGetBanksQuery();

  const banks =
    useMemo(
      () =>
        banksData?.banks?.map((i) => {
          return { label: i.name, value: i.id };
        }),
      [banksData?.banks]
    ) || [];

  return { banks };
};

export default usePayments;
