import React from "react";
import { useTypedSelector } from "../../hook/useTypedSelector";
import { Navigate } from "react-router-dom";

const AllowUser = ({ children }) => {
  const { user } = useTypedSelector((store) => store.appUser);

  return user ? children : <Navigate to="/" />;
};

export default AllowUser;
