export function _currency_format(digit, cur, local) {
  return new Intl.NumberFormat(local ?? "en-NG", {
    style: "currency",
    currency: cur,
  }).format(digit);
}

export function _figure_format(digit, local) {
  return new Intl.NumberFormat(local ?? "en-NG").format(digit);
}

export const isEmpty = (obj) => {
  return Object.values(obj).length === 0;
};

export const isEmptyString = (myString) => {
  return myString.trim() === "";
};

export const errorProps = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
};

export const successProps = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

export const neutralProps = {
  position: "top-center",
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: "dark",
  className: "what",
};
