import { useMemo } from "react";
import { useGetDivisionsQuery } from "../store/query/utility";

const usePublicDivision = () => {
  const { data: divisons } = useGetDivisionsQuery();

  const divisonList = useMemo(
    () =>
      divisons?.data?.map((i) => {
        return { label: i.name, value: i.id };
      }),
    [divisons?.data]
  ) || []

  return { divisonList };
};

export default usePublicDivision;
