import React, { memo } from "react";
import Dialog from "@mui/material/Dialog";
import "./CustomDialog.css";

const CustomDialog = ({ open, onClose, children }) => {
  return (
    <Dialog onClose={onClose} open={open}>
      <div className="modal-container">{children}</div>
    </Dialog>
  );
};

export default memo(CustomDialog);
