import { api } from "../BaseConfig";

export const applicantsSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getApplicantsSingleJob: builder.query({
      query: (params) => ({
        url: `/${params.type}/view-applicant/${params.id}`,
        method: "GET",
      }),
    }),
    updateAppSingleJob: builder.mutation({
      query: (data) => {
        return {
          url: `/${data.type}/${data.option}-applicant/${data.appId}`,
          method: "POST",
          body: data.values,
        };
      },
    }),
    getActivityJobs: builder.query({
      query: () => ({
        url: `/individual/view-application`,
        method: "GET",
      }),
    }),
    fillTimeSheet: builder.mutation({
      query: (data) => {
        return {
          url: `/individual/fill-timesheet`,
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetApplicantsSingleJobQuery,
  useUpdateAppSingleJobMutation,
  useGetActivityJobsQuery,
  useFillTimeSheetMutation,
} = applicantsSlice;
