import React from "react";
import PhoneInput from "react-phone-input-2";
import "../profileEditInput/ProfileEditInput.css";
import "./CustomPhoneInput.css";

const CustomPhoneInput = ({
  id,
  label,
  value,
  onChange,
  touched,
  error,
  onBlur,
  customStyle,
}) => {
  const africanCountries = [
    "ng",
  ];

  const styles = {
    bgInput: {
      width: "100%",
      position: "relative",
    },
    error: {
      fontSize: "10px",
      color: "red",
      paddingLeft: "15px",
    },
  };

  return (
    <div className="customInput-contain">
      <label
        style={{
          color: "#777",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",
          ...customStyle?.label,
        }}
      >
        {label}
      </label>
      <div style={styles.bgInput}>
        <PhoneInput
          inputProps={{
            id: id
          }}
          specialLabel={""}
          country={"ng"}
          countryCodeEditable={false}
          onlyCountries={africanCountries}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
        {touched && error && <span style={styles.error}>{error}</span>}
      </div>
    </div>
  );
};

export default CustomPhoneInput;
