import React, { useEffect, useMemo, useState } from "react";
import "./Shortlist.css";
import JobItem from "../../../components/jobItem/JobItem";
import { useNavigate } from "react-router-dom";
import FilterInput from "../../../components/filterBox/FilterInput";
import { useTypedSelector } from "../../../hook/useTypedSelector";
import PaginationContainer from "../../../components/paginationContainer/PaginationContainer";
import CustomAutoComplete from "../../../components/customAutoComplete/CustomAutoComplete";
import usePublicDivision from "../../../hook/usePublicDivision";
import useRegionLocation from "../../../hook/useRegionLocation";
import { useGetJobsQuery } from "../../../store/query/jobs";

const Shortlist = () => {
  const [filters, setFilters] = useState({
    page: 1,
    date_from: "",
    date_to: "",
  });
  const { data: jobs, isLoading } = useGetJobsQuery(filters);
  const { user } = useTypedSelector((store) => store.appUser);
  const { divisonList } = usePublicDivision();
  const newDivisionList = [{ label: "All", value: 0 }, ...divisonList];

  const { states, cities, setStateId } = useRegionLocation();

  useEffect(() => {
    setStateId(filters?.state_id);
  }, [setStateId, filters?.state_id]);

  const findSingleDivision =
    useMemo(() => {
      return divisonList?.find((i) => i.label === user?.division?.name);
    }, [divisonList, user]) || null;

  useEffect(() => {
    if (user?.division && findSingleDivision) {
      setFilters((prev) => ({ ...prev, division: findSingleDivision?.value }));
    }
  }, [user?.division, findSingleDivision]);

  const navigate = useNavigate();
  const onPageChange = (event, value) => {
    setFilters((prev) => ({ ...prev, page: value }));
  };

  return (
    <div className="shortlist-container">
      <div className="shortlist-right">
        <div className="shortlist-right-bottom">
          <div className="shortlist-header">
            <h2>Job Listings</h2>
          </div>

          <div className="shortlist-header-below">
            {user?.account_type === "organisation" && (
              <div className="shortist-auto-complete">
                <CustomAutoComplete
                  isClearable={false}
                  placeholder={"Division"}
                  otherValue={filters.division}
                  options={newDivisionList}
                  customStyles={{
                    control: {
                      width: "100%",
                      borderRadius: "0",
                    },
                    valueContainer: {
                      height: "45px",
                    },
                  }}
                  onChange={(option) => {
                    setFilters((prev) => ({
                      ...prev,
                      division: option?.value,
                    }));
                  }}
                />
              </div>
            )}

            <div className="shortist-auto-complete">
              <CustomAutoComplete
                isClearable={false}
                placeholder={"State"}
                options={states}
                customStyles={{
                  control: {
                    width: "100%",
                    borderRadius: "0",
                  },
                  valueContainer: {
                    height: "45px",
                  },
                }}
                onChange={(option) => {
                  setFilters((prev) => ({
                    ...prev,
                    state_id: option?.value,
                  }));
                }}
              />
            </div>

            <div className="shortist-auto-complete">
              <CustomAutoComplete
                isClearable={false}
                placeholder={"City"}
                options={cities}
                customStyles={{
                  control: {
                    width: "100%",
                    borderRadius: "0",
                  },
                  valueContainer: {
                    height: "45px",
                  },
                }}
                onChange={(option) => {
                  setFilters((prev) => ({
                    ...prev,
                    city_id: option?.value,
                  }));
                }}
              />
            </div>

            <div className="shortist-auto-complete">
              <div className="shortlist-dates">
                <span>Dates:</span>
                <div className="date-listing">
                  <FilterInput
                    customStyles={{
                      bg: {
                        height: "45px",
                        backgroundColor: "#fff",
                        borderRadius: "4px",
                        border: "none",
                      },
                      input: {
                        width: "100%",
                      },
                    }}
                    placeholder="DD-MM-YYY"
                    type={"date"}
                  />
                </div>

                <div className="date-listing">
                  <FilterInput
                    customStyles={{
                      bg: {
                        height: "45px",
                        backgroundColor: "#fff",
                        borderRadius: "4px",
                        border: "none",
                      },
                      input: {
                        width: "100%",
                      },
                    }}
                    placeholder="DD-MM-YYY"
                    type={"date"}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="shortlist-right-content">
            <PaginationContainer
              loading={isLoading}
              data={jobs?.data?.allJob?.data}
              onPageChange={onPageChange}
              page={filters.page}
              total={jobs?.data?.allJobCount ?? 0}
            >
              <div className="job-list-container">
                {jobs?.data?.allJob?.data?.map((job) => {
                  return (
                    <div
                      onClick={() => navigate(`/joblistings/${job.id}`)}
                      className="shortlist-jobItem"
                      key={job.id}
                    >
                      <JobItem
                        title={job.title}
                        rate={job.hourly_rate}
                        jobLoc={`${job.city}, ${job.state}`}
                        jobDesc={job.description}
                        jobOrg={job.company_name}
                        jobImg={job.logo}
                      />
                    </div>
                  );
                })}
              </div>
            </PaginationContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shortlist;
