import { useMemo, useState } from "react";
import {
  useGetCitiesByStateQuery,
  useGetStatesQuery,
} from "../store/query/location";

const useRegionLocation = () => {
  const [stateId, setStateId] = useState(null);
  const { data: statesList } = useGetStatesQuery();
  const { data: citiesList } = useGetCitiesByStateQuery({ stateId });

  const states =
    useMemo(
      () =>
        statesList?.data?.allState?.map((i) => {
          return { label: i.name, value: i.id };
        }),
      [statesList?.data]
    ) || [];

  const cities =
    useMemo(
      () =>
        citiesList?.cities?.map((i) => {
          return { label: i.name, value: i.id };
        }),
      [citiesList?.cities]
    ) || [];

  return { states, cities, setStateId };
};

export default useRegionLocation;
