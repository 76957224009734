import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFillTimeSheetMutation, useGetActivityJobsQuery } from "../store/query/applicants";

const useApplicationStatus = () => {
  const [jobs, setJobs] = useState({
    applied: [],
    shortlisted: [],
    rejected: [],
    saved: [],
  });
  const { data, isLoading, refetch } = useGetActivityJobsQuery();
  const [fillTimeSheet, { isLoading: isFillTimeSheet }] =
    useFillTimeSheetMutation();

  const fillTimeSheetJob = useCallback(
    async (values) => {
      const res = await fillTimeSheet(values);
      if (res?.data?.status === true) {
        toast.success(res?.data?.message);
        refetch();
      } else {
        toast.error("Failed to Fill TimeSheet");
      }
    },
    [fillTimeSheet, refetch]
  );

  useEffect(() => {
    if (data?.status) {
      setJobs({
        applied: data?.data?.allAppliedJob,
        shortlisted: data?.data?.allShortlistedJob,
        rejected: data?.data?.allRejectedJob,
        saved: data?.data?.allSavedJob,
      });
    } else {
      setJobs({
        applied: [],
        shortlisted: [],
        rejected: [],
        saved: [],
      });
    }
  }, [data]);

  return { isLoading, jobs, isFillTimeSheet, fillTimeSheetJob };
};

export default useApplicationStatus;
