import React from "react";
import "./ForgetPassword.css";
import Logo from "../../../assets/fmlogo-login.png";
import Doctor from "../../../assets/doc1.png";
import { Link } from "react-router-dom";
import Logo2 from "../../../assets/Group.png";
import CustomInput from "../signUp/CustomInput";
import useForgetPassword from "../../../hook/useForgetPassword";
import CustomButton from "../../../components/customButton/CustomButton";

const ForgetPassword = () => {
  const {
    values,
    handleChange,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    isValid,
    isLoading,
  } = useForgetPassword();

  const formSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <div className="login-container">
      <div className="login-left">
        <div className="login-left-content">
          <Link to="/">
            <div className="logo">
              <img src={Logo} alt="logo" />
            </div>
          </Link>

          <div className="login-left-text">
            <h3>
              Embrace flexible and rewarding practice through healthcare locum
            </h3>
            <p>
              Join the largest community of healthcare professionals on locum
              staffing in Africa as a Healthcare professional; and kick-start a
              flexible and rewarding practice.
            </p>
          </div>
          <div className="login-left-image">
            <img src={Doctor} alt="" />
          </div>
        </div>
      </div>
      <div className="login-right">
        <div className="login-right-body">
          <div className="login-button-box">
            <Link to="/">
              <img src={Logo2} alt="logo" />
            </Link>
            <Link to="/login" className="">
              <CustomButton
                customStyles={{
                  width: "85px",
                  height: "40px",
                  marginTop: "0",
                  fontSize: "15px",
                  borderRadius: "5px",
                }}
                title="Login"
              />
            </Link>
          </div>

          <div className="login-right-content">
            {/* form */}
            <form onSubmit={formSubmit}>
              <div className="form-label">
                <CustomInput
                  id="email"
                  label="Email address or email"
                  placeholder="Enter email address or phone number"
                  type="email"
                  value={values.email}
                  onChange={handleChange("email")}
                  touched={touched.email}
                  error={errors.email}
                  onBlur={(e) => handleBlur(e)}
                />
              </div>

              <CustomButton
                disable={!isValid}
                type="submit"
                title="Request Reset Link"
                loading={isLoading}
              />

              <h5 className="account">
                Don't have an account?{" "}
                <Link to="/register">
                  <span className="join">Join Now</span>
                </Link>{" "}
              </h5>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
