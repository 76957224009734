import React, { useEffect } from "react";
import "./FilterBox.css";
import FilterInput from "./FilterInput";
import CustomAutoComplete from "../customAutoComplete/CustomAutoComplete";
import ProfileEditDate from "../profileEditDate/ProfileEditDate";
import CustomButton from "../customButton/CustomButton";
import usePublicDivision from "../../hook/usePublicDivision";
import useRegionLocation from "../../hook/useRegionLocation";

const FilterBox = ({ setFilters, filters }) => {
  const { divisonList } = usePublicDivision();
  const newDivisionList = [{ label: "All", value: 0 }, ...divisonList];

  const { states, cities, setStateId } = useRegionLocation();

  useEffect(() => {
    setStateId(filters.state_id);
  }, [setStateId, filters.state_id]);

  return (
    <div className="filter-box-container">
      <div className="filters-box-wrapper">
        <h3 className="filter-header">Filters</h3>

        <div className="column">
          <div>
            <h4>Divisions</h4>

            <div className="top">
              <div>
                <CustomAutoComplete
                  isClearable={false}
                  placeholder={"Select division"}
                  otherValue={filters.division}
                  options={newDivisionList}
                  customStyles={{
                    control: {
                      width: "100%",
                      borderRadius: "0",
                    },
                  }}
                  onChange={(option) => {
                    setFilters((prev) => ({
                      ...prev,
                      division: option?.value,
                    }));
                  }}
                />
              </div>
            </div>
          </div>

          <div>
            <h4>Location</h4>

            <div className="full-auto-complete">
              <div className="loc-first">
                <CustomAutoComplete
                  isClearable={false}
                  placeholder={"state"}
                  otherValue={filters.state_id}
                  options={states}
                  customStyles={{
                    control: {
                      width: "100%",
                      borderRadius: "4px",
                    },
                  }}
                  onChange={(option) => {
                    setFilters((prev) => ({
                      ...prev,
                      state_id: option?.value,
                    }));
                  }}
                />
              </div>
              <div className="loc-first">
                <CustomAutoComplete
                  isClearable={false}
                  placeholder={"city"}
                  otherValue={filters.city_id}
                  options={cities}
                  customStyles={{
                    control: {
                      width: "100%",
                      borderRadius: "4px",
                    },
                  }}
                  onChange={(option) => {
                    setFilters((prev) => ({
                      ...prev,
                      city_id: option?.value,
                    }));
                  }}
                />
              </div>
            </div>
          </div>

          <div>
            <h4>Pay Rate ₦</h4>
            <div className="jobListings-topSalary">
              <div className="flex-input">
                <div className="date-filter-jobs">
                  <FilterInput
                    placeholder="Min"
                    type="number"
                    endIcon={<p className="icon">₦</p>}
                    value={filters?.min}
                    onChange={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        min: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="date-filter-jobs">
                  <FilterInput
                    placeholder="Max"
                    type="number"
                    endIcon={<p className="icon">₦</p>}
                    value={filters?.max}
                    onChange={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        max: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="jobListings-topSalary">
              <div className="flex-input">
                <div className="date-filter-jobs">
                  <ProfileEditDate
                    label="Start Date"
                    placeholder="DD-MM-YYY"
                    widthCon={"100%"}
                    type="date"
                    value={filters.date_from}
                    customStyles={{
                      label: {
                        color: "#245293",
                        fontWeight: 600,
                      },
                      input: {
                        borderRadius: "0px",
                        border: "1px solid #efefef",
                      },
                    }}
                    onChange={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        date_from: e.target.value,
                      }));
                    }}
                  />
                </div>

                <div className="date-filter-jobs">
                  <ProfileEditDate
                    label="End Date"
                    placeholder="DD-MM-YYY"
                    widthCon={"100%"}
                    type="date"
                    value={filters.date_to}
                    customStyles={{
                      label: {
                        color: "#245293",
                        fontWeight: 600,
                      },
                      input: {
                        borderRadius: "0px",
                        border: "1px solid #efefef",
                      },
                    }}
                    onChange={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        date_to: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="reset-filters">
          <CustomButton
            variant="outline"
            onClick={() =>
              setFilters({
                division: null,
                state_id: null,
                city_id: null,
                min: "",
                max: "",
                page: 1,
              })
            }
            title="Reset Filters"
            customStyles={{
              borderRadius: "0px",
            }}
            color={"red"}
            border={"red"}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterBox;
