import { useDispatch } from "react-redux";
import { exitUser } from "../store/slices/user";
import { successProps } from "../utils";
import { toast } from "react-toastify";

const useLogout = () => {
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(exitUser({}));
    toast.success("Successfully Logged out", successProps);
  };

  return logOut;
};

export default useLogout;
