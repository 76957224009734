import React, { useState } from "react";
import { AiOutlineStar } from "react-icons/ai";
import Button from "@mui/material/Button";
import CustomMenu from "../customMenu/CustomMenu";
import CustomButton from "../customButton/CustomButton";
import { _currency_format, isEmpty } from "../../utils";
import { config } from "../../config";
import { Link } from "react-router-dom";
import DashboardModal from '../../components/dashboardModal/DashboardModal'

const SingleCandidates = ({
  name,
  review,
  status,
  date,
  tag,
  handleAction,
  cv,
  appId,
  updateApplicant,
  filled_timesheet
}) => {
  const [showTimesheetModal, setShowTimesheetModal] = useState(false);
  const file = !isEmpty(cv) && `${config.getPublicImages}/${cv}`;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (status !== "Completed") {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options =
    tag === 3
      ? [
          {
            name: "Complaint",
            onClick: () => {
              handleAction();
            },
          },
          { name: "Completed", onClick: () => {} },
        ]
      : tag === 2
      ? [
          { name: "Accept", onClick: () => {} },
          { name: "Reject", onClick: () => {} },
          { name: "Details", onClick: () => {} },
        ]
      : tag === 1
      ? [{ name: "Details", onClick: () => {} }]
      : [
          {
            name: "Shortlist candidate",
            onClick: () =>
              updateApplicant({
                option: "shortlist",
                appId,
              }),
          },
          { name: "Details", onClick: () => {} },
        ];

  const complaintOptions =
    status === "Complaint" ? [{ name: "Resolve", onClick: () => {} }] : options;

  return (
    <div className="single-candidate">
      <div className="single-candidate-left">
        <h5>Name</h5>
        <h5>Review</h5>
        <h5>Status</h5>
        <h5>Date Applied</h5>
        <h5>Action</h5>
      </div>

      <div className="single-candidate-right">
        <span className="name">
          <span className="charAt">{name.charAt(0).toUpperCase()}</span>
          {name}
        </span>
        <span className="single review">
          <AiOutlineStar /> {review}
        </span>
        <span className="single review">{date}</span>
        <div className="status">
          <span
            className={
              status === "Pending"
                ? "pend"
                : status === "Successful"
                ? "succ"
                : status === "Rejected"
                ? "rej"
                : status === "Complaint"
                ? "rej"
                : status === "Completed"
                ? "succ"
                : status === "Ongoing"
                ? "pend"
                : null
            }
          >
            {status}
          </span>
        </div>

        {tag === 2 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CustomButton
              variant="outline"
              onClick={() => {
                setShowTimesheetModal(!showTimesheetModal);
              }}
              customStyles={btnStyled}
              title="Timesheet"
              border="#d8dde5"
            />
          </div>
        ) : (
          <Link
            to={file && file}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CustomButton
              variant="outline"
              customStyles={btnStyled}
              title="View CV"
              border="#d8dde5"
            />
          </Link>
        )}
        <div className="dot-bg">
          <Button onClick={handleClick} disabled={status === "Completed"}>
            <span className="dot">...</span>
          </Button>
        </div>
        <CustomMenu
          anchorEl={anchorEl}
          handleClose={handleClose}
          options={complaintOptions}
        />
        <div className="mobile-options">
          {status !== "Completed" &&
            complaintOptions.map((option, index) => (
              <button
                key={index}
                className={`${index === 0 ? "message" : "delete"}`}
                onClick={option.onClick}
              >
                {option.name}
              </button>
            ))}
        </div>
      </div>

      <DashboardModal
        //onClose={() => setShowTimesheetModal(false)}
        isOpen={showTimesheetModal}
      >
        <FillTimeSheet
          filled_timesheet={filled_timesheet}
          onClose={() => setShowTimesheetModal(false)}
          //loading={loading}
        />
      </DashboardModal>
    </div>
  );
};

export default SingleCandidates;

const btnStyled = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  padding: "2px 8px",
  borderRadius: "0px",
  BsBorderWidth: "1px",
  fontSize: "13px",
  height: "30px",
  marginTop: "0px",
};

const FillTimeSheet = ({ filled_timesheet, onClose, loading }) => {
  return (
    <div className="timesheet-con">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div></div>
        <h3>Timesheet</h3>

        <span onClick={onClose} style={{ fontSize: "20px", fontWeight: 800 }}>
          X
        </span>
      </div>

      <div className="timesheet-headings">
        <span>Start Date</span>
        <span>End Date</span>
        <span>Start Time</span>
        <span>End Time</span>
        <span>Hours</span>
        <span>Charges</span>
      </div>
      <div className="timesheet-result-box">
        {filled_timesheet?.map((item, i) => (
          <div key={i} className="single-candidate">
            <div className="single">
              <span className="mobile-time">Start Date:</span>
              <p>{item.start_date}</p>
            </div>
            <div className="single">
              <span className="mobile-time">End Date:</span>
              <p>{item.end_date}</p>
            </div>
            <div className="single">
              <span className="mobile-time">Start Time:</span>
              <p>{item.start_time}</p>
            </div>
            <div className="single">
              <span className="mobile-time">End Time:</span>
              <p>{item.end_time}</p>
            </div>
            <div className="single">
              <span className="mobile-time">Hours:</span>
              <p>{item.hour}</p>
            </div>
            <div className="single">
              <span className="mobile-time">Charges:</span>
              <p>{_currency_format(Number(item.charge), "NGN")}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="timesheet-footer">
        <div className="timesheet-btn">
          <CustomButton
            loading={loading}
            title="Submit"
            customStyles={{
              width: "100%",
              borderRadius: "5px",
              height: 35,
            }}
          />
        </div>
      </div>
    </div>
  );
};