import { Pagination } from "@mui/material";
import React from "react";
import { Bars } from "react-loader-spinner";
import "./Pagination.css";
import emptyIcon from "../../assets/empty.png";

const PaginationContainer = ({
  loading,
  page,
  total,
  onPageChange,
  children,
  data,
}) => {
  if (loading) {
    return (
      <div className="pag-screen">
        <Bars
          height="80"
          width="80"
          color=" #245293"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  if (total === 0 || (data.length === 0 && !loading)) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          alignItems: "center",
          justifyContent: "center",
          minHeight: "400px",
        }}
      >
        <img src={emptyIcon} alt="empty record" className="" />
        <p className="">No record found</p>
      </div>
    );
  }

  return (
    <div>
      {children}
      <div className="pag-right">
        <Pagination
          count={Math.ceil(total / 10)}
          page={page}
          onChange={onPageChange}
          variant="outlined"
          color="primary"
          sx={{
            backgroundColor: "#f0f0f0",
            padding: "10px",
            borderRadius: "8px",
            "& .MuiPaginationItem-root": {
              color: "#245293",
            },
            "& .MuiPaginationItem-page.Mui-selected": {
              backgroundColor: "#245293",
              color: "white",
            },
          }}
        />
      </div>
    </div>
  );
};

export default PaginationContainer;
