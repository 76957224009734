import { useFormik } from "formik";
import { useTypedSelector } from "./useTypedSelector";
import { toast } from "react-toastify";
import { errorProps, successProps } from "../utils";
import { useEffect, useMemo, useState } from "react";
import { useUpdateUserMutation } from "../store/query/auth";
import useLogin from "./useLogin";
import usePublicDivision from "./usePublicDivision";

const useUpdateUser = () => {
  const { user, token } = useTypedSelector((store) => store.appUser);
  const [initialValues, setInitialValues] = useState(null);
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const { divisonList } = usePublicDivision();
  const login = useLogin();
  const loading = isLoading || login.loading;

  const findSingleDivision = useMemo(() => {
    return divisonList?.find((i) => i.label === user?.division?.name ?? "");
  }, [divisonList, user?.division?.name]);

  useEffect(() => {
    if (user) {
      setInitialValues({
        name: user.name || "",
        email: user.email || "",
        phone: user.phone || "",
        about_us: user.about_us || "",
        address: user.address || "",
        career_city: user.career_city || "",
        career_state: user.career_state || "",
        operation_city: user.operation_city || "",
        operation_state: user.operation_state || "",
        organisation_role: null,
        city: user.city || "",
        state: user.state || "",
        country: "Nigeria",
        cv: null,
        date_of_birth: user.date_of_birth || "",
        division: findSingleDivision,
        postal_code: user.postal_code || "",
        profile_pix: null,
        skills: user.skills,
        license: null,
        gender: user.gender || "",
        _method: "PUT",
      });
    }
  }, [user, findSingleDivision]);

  const handleSubmit = async (values) => {
    const res = await updateUser(values);
    if ("data" in res) {
      const { data } = res;

      if (data.status) {
        const body = {
          token: token,
          acc_type: user?.account_type,
        };
        await login.getUser(body, "/dashboard");
        toast.success(data.message, successProps);
      }
    } else if ("error" in res) {
      const { error } = res;
      if (error.status === 400) {
        toast.error("Fill all Field", errorProps);
      } else if (error?.status === "FETCH_ERROR") {
        toast.error("Network Error", errorProps);
      } else {
        toast.error("Failed to Update", errorProps);
      }
    }
  };

  const formik = useFormik({
    initialValues: initialValues || {},
    enableReinitialize: true,
    onSubmit: (values) => {
      if (user?.account_type === "organisation") {
        const {
          career_city,
          career_state,
          division,
          license,
          organisation_role,
          gender,
          ...restOr
        } = values;
        const mainValues = {
          organisation_role_id: organisation_role?.value,
          type: user?.account_type,
          ...restOr,
        };
        handleSubmit(mainValues);
      } else if (user?.account_type === "individual") {
        const {
          operation_city,
          operation_state,
          organisation_role,
          division,
          ...restIn
        } = values;
        const mainValues = {
          division_id: division?.value,
          type: user?.account_type,
          ...restIn,
        };
        handleSubmit(mainValues);
      }
    },
  });

  return { ...formik, loading };
};

export default useUpdateUser;
