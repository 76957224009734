import { api } from "../BaseConfig";

export const paymentsSlice = api.injectEndpoints({
  endpoints: (builder) => ({
    getBanks: builder.query({
      query: () => ({
        url: `/banks`,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetBanksQuery } = paymentsSlice;