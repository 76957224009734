import React from "react";
import { Navigate } from "react-router-dom";
import { useTypedSelector } from "../../hook/useTypedSelector";

const IndividualProtection = ({ children }) => {
  const { user } = useTypedSelector((store) => store.appUser);
  return user?.account_type === "individual" ? children : <Navigate to="/" />;
};

export default IndividualProtection;
