import React, { useState } from "react";
import JobItem from "../jobItem/JobItem";
import { jobsArray } from "../findJobs/Jobs";
import { Link } from "react-router-dom";
import { CiSearch, CiLocationOn } from "react-icons/ci";
import { GoArrowRight } from "react-icons/go";
import { Autocomplete } from "@mui/material";

const AllJobsItem = () => {
  const [visible, setVisible] = useState(3);
  const [searchLocationValue, setSearchLocationValue] = useState("");
  const [searchTitleValue, setSearchTitleValue] = useState("");
  const [allData, setAllData] = useState(jobsArray);
  const top100Films = [];

  const loadMore = () => {
    setVisible(visible + 3);
  };

  const searchLocationHandler = () => {
    const searchedTitle = jobsArray.filter((job) => {
      return job.location
        .toLowerCase()
        .includes(searchLocationValue.toLowerCase());
    });
    const searchedLocation = searchedTitle.filter((job) => {
      return job.jobTitle
        .toLowerCase()
        .includes(searchTitleValue.toLowerCase());
    });
    setAllData(searchedLocation);
  };

  return (
    <div>
      {/* search box */}
      <div className="findJobs-search-box">
        <div className="input-1">
          <CiSearch />
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            sx={{ width: "240px", height: "20px" }}
            options={top100Films.map((option, i) => option.title)}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <input
                  placeholder="Job Title"
                  type="text"
                  {...params.inputProps}
                  style={{ backgroundColor: "transparent" }}
                />
              </div>
            )}
          />
          {/* <input type="text" placeholder='Job Title' onChange={(e) => { setSearchTitleValue(e.target.value) }} /> */}
        </div>
        <div className="input-1">
          <CiLocationOn />
          <input
            type="text"
            placeholder="Location"
            onChange={(e) => {
              setSearchLocationValue(e.target.value);
            }}
          />
        </div>
        <div className="findJobs-button">
          <button onClick={searchLocationHandler}>
            Find Jobs <GoArrowRight className="arrow-right" />
          </button>
        </div>
      </div>
      {/* search box */}

      <div className="proxy-job-container">
        {allData.length === 0 ? (
          <h2 className="no-jobs-found"> No Jobs Found! </h2>
        ) : (
          <div className="job-result-container">
            {allData?.slice(0, visible).map((job, i) => {
              return (
                <Link to={`/joblistings/${job.Id}`} key={i}>
                  <div className="findJobs-jobitem">
                    <JobItem
                      title={job.jobTitle}
                      rate={job.rate}
                      jobLoc={job.location}
                      jobDesc={job.des}
                      jobOrg={job.organization}
                      jobImg={job.oranizationIMG}
                      jobOrgloc={job.organizationLocation}
                    />
                  </div>
                </Link>
              );
            })}
          </div>
        )}
      </div>

      {allData.length === 0 ? null : visible < allData.length ? (
        <div className="button-full">
          <button className="job-view-more" onClick={loadMore}>
            View More
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default AllJobsItem;
