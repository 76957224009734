import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import "./ProfileEdit.css";
import { GoArrowRight } from "react-icons/go";
import { CgProfile } from "react-icons/cg";
import ProfileEditInput from "../../../components/profileEditInput/ProfileEditInput";
import CustomPhoneInput from "../../../components/customPhoneInput/CustomPhoneInput";
import ProfileEditDate from "../../../components/profileEditDate/ProfileEditDate";
import useCountries from "../../../hook/useCountries";
import toLower from "lodash/fp/toLower";
import CalendarIcon from "../../../components/icon/CalendarIcon";
import FileIcon from "../../../components/icon/FileIcon";
import { useDropzone } from "react-dropzone";
import useUpdateUser from "../../../hook/useUpdateUser";
import { useTypedSelector } from "../../../hook/useTypedSelector";
import useSkills from "../../../hook/useSkills";
import { errorProps, isEmpty } from "../../../utils";
import { config } from "../../../config";
import useOrganisationRole from "../../../hook/useOrganisationRole";
import CustomButton from "../../../components/customButton/CustomButton";
import { toast } from "react-toastify";
import CustomAutoComplete from "../../../components/customAutoComplete/CustomAutoComplete";
import useRegionLocation from "../../../hook/useRegionLocation";
import usePublicDivision from "../../../hook/usePublicDivision";

const genders = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const ProfileEdit = () => {
  const { user } = useTypedSelector((store) => store.appUser);
  const [profilePics, setProfilePics] = useState(null);
  const [profileCv, setProfileCv] = useState(null);
  const [license, setLicense] = useState(null);
  const { skills } = useSkills();
  const { divisonList } = usePublicDivision();
  const { roles } = useOrganisationRole();
  const [skill, setSkill] = useState();
  const countries = useCountries();
  const { states, cities, setStateId } = useRegionLocation();
  const {
    states: secondStates,
    cities: secondCities,
    setStateId: secondSetStateId,
  } = useRegionLocation();
  const {
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    setFieldValue,
    loading,
  } = useUpdateUser();

  const selectedProvinceOption = useMemo(() => {
    return states?.find((i) => toLower(i.label) === toLower(values.state));
  }, [states, values.state]);

  const cityOption = useMemo(() => {
    return cities?.find((i) => toLower(i.label) === toLower(values.city));
  }, [cities, values.city]);

  const selSecondProvinceOption = useMemo(() => {
    return secondStates?.find(
      (i) =>
        toLower(i.label) ===
        toLower(values.career_state || values.operation_state)
    );
  }, [secondStates, values.career_state, values.operation_state]);

  const selSecondCityOption = useMemo(() => {
    return secondCities?.find(
      (i) =>
        toLower(i.label) ===
        toLower(values.career_city || values.operation_city)
    );
  }, [secondCities, values.career_city, values.operation_city]);

  const selectedGender = useMemo(() => {
    return genders?.find((i) => toLower(i.label) === toLower(values.gender));
  }, [genders, values.gender]);

  useEffect(() => {
    setStateId(selectedProvinceOption?.value);
  }, [selectedProvinceOption?.value, setStateId]);

  useEffect(() => {
    secondSetStateId(selSecondProvinceOption?.value);
  }, [selSecondProvinceOption?.value, secondSetStateId]);

  const findSingleDivision = useMemo(() => {
    return divisonList?.find((i) => i.label === user?.division?.name ?? "");
  }, [divisonList, user?.division?.name]);

  const findSingleRole = useMemo(() => {
    return roles?.find((i) => i.label === user?.organisation_role?.name);
  }, [roles, user?.organisation_role]);

  useEffect(() => {
    setFieldValue("organisation_role", findSingleRole);
    setFieldValue("division", findSingleDivision);
  }, [setFieldValue, findSingleDivision, findSingleRole]);

  const handleAddSkill = () => {
    if (!isEmpty(skill)) {
      if (!values.skills.includes(skill.label)) {
        const newSkills = [...values.skills, skill.label];
        setFieldValue("skills", newSkills);
        setSkill();
      } else {
        setSkill();
      }
    }
  };

  const handleChangeSelectRole = (option) => {
    handleChange({
      target: {
        name: "organisation_role",
        value: option,
      },
    });
  };

  const handleDeleteSkill = (id) => {
    const updatedSkillList = values.skills?.filter((i, index) => index !== id);
    setFieldValue("skills", updatedSkillList);
  };

  const countrySelectedOption = useMemo(() => {
    return countries.find((i) => toLower(i.name) === toLower(values.country));
  }, [countries, values.country]);

  const changeCountryHandler = useCallback(
    (option) => {
      handleChange({
        target: {
          name: "country",
          value: option?.name,
        },
      });
      handleChange({
        target: {
          name: "state",
          value: "",
        },
      });
    },
    [handleChange]
  );

  const onChangeState = useCallback(
    (option) => {
      handleChange({
        target: {
          name: "state",
          value: option?.label,
        },
      });
    },
    [handleChange]
  );

  const onChangeCity = useCallback(
    (option) => {
      handleChange({
        target: {
          name: "city",
          value: option?.label,
        },
      });
    },
    [handleChange]
  );

  const onChangeGender = useCallback(
    (option) => {
      handleChange({
        target: {
          name: "gender",
          value: option?.label,
        },
      });
    },
    [handleChange]
  );

  const handleUploadProfilePics = useCallback(
    (files) => {
      const file = files[0];
      const fileSizeInKB = file?.size / 1024;
      const fileSizeInMB = fileSizeInKB / 1024;

      const sizeAllowed = 1 >= fileSizeInMB;
      const isAllow = file.type === "image/jpeg" || file.type === "image/png";

      if (isAllow) {
        if (sizeAllowed) {
          setProfilePics(
            ...files.map((file) => {
              handleChange({
                target: {
                  name: "profile_pix",
                  value: Object.assign(file, {
                    preview: URL.createObjectURL(file),
                  }),
                },
              });
              return Object.assign(file, {
                preview: URL.createObjectURL(file),
              });
            })
          );
        } else {
          toast.error(
            "Profile Image should be not be more than 1MB",
            errorProps
          );
        }
      } else {
        toast.error("Profile Image should be in PNG or JPEG", errorProps);
      }
    },
    [handleChange]
  );

  const handleUploadProfileCv = useCallback(
    (files) => {
      const file = files[0];
      const isPDFByExtension = file.name.endsWith(".pdf");
      const isPDFByMimeType = file.type === "application/pdf";
      const fileSizeInKB = file?.size / 1024;
      const fileSizeInMB = fileSizeInKB / 1024;
      const sizeAllowed = 1 >= fileSizeInMB;

      if (isPDFByExtension || isPDFByMimeType) {
        if (sizeAllowed) {
          setProfileCv(
            ...files.map((file) => {
              handleChange({
                target: {
                  name: "cv",
                  value: Object.assign(file, {
                    preview: URL.createObjectURL(file),
                  }),
                },
              });
              return Object.assign(file, {
                preview: URL.createObjectURL(file),
              });
            })
          );
        } else {
          toast.error("CV should be not be more than 1MB", errorProps);
        }
      } else {
        toast.error("CV should be in pdf format", errorProps);
      }
    },
    [handleChange]
  );

  const handleUploadLicense = useCallback(
    (files) => {
      const file = files[0];
      const isAllow = file.type === "image/jpeg" || file.type === "image/png";
      const fileSizeInKB = file?.size / 1024;
      const fileSizeInMB = fileSizeInKB / 1024;

      const sizeAllowed = 1 >= fileSizeInMB;

      console.log(sizeAllowed);

      if (isAllow) {
        if (sizeAllowed) {
          setLicense(
            // eslint-disable-next-line array-callback-return
            ...files.map((file) => {
              handleChange({
                target: {
                  name: "license",
                  value: Object.assign(file, {
                    preview: URL.createObjectURL(file),
                  }),
                },
              });
              Object.assign(file, { preview: URL.createObjectURL(file) });
            })
          );
        } else {
          toast.error("License should not be more than 1MB", errorProps);
        }
      } else {
        toast.error("License should be in PNG or JPEG", errorProps);
      }
    },
    [handleChange]
  );

  const {
    getRootProps: getRootProfilePicsProps,
    getInputProps: getInputProfilePicsProps,
  } = useDropzone({ onDrop: handleUploadProfilePics });

  const {
    getRootProps: getRootProfileCvProps,
    getInputProps: getInputProfileCvProps,
  } = useDropzone({ onDrop: handleUploadProfileCv });

  const {
    getRootProps: getRootLicenseProps,
    getInputProps: getInputLicenseProps,
  } = useDropzone({ onDrop: handleUploadLicense });

  const handleDateChange = (event) => {
    setFieldValue("date_of_birth", event.target.value);
  };

  const displayProfilePics = user?.profile_pix || profilePics?.preview;
  const sourceImg =
    profilePics?.preview || `${config.getPublicImages}/${user?.profile_pix}`;

  return (
    <div className="profile-edit-container">
      <div className="heading">
        <h6>Profile</h6>
      </div>

      <div className="profile-edit-content">
        <div className="part">
          <h2>1. Your Profile</h2>

          <div className="input-group">
            <div className="upload-img-bg">
              {displayProfilePics ? (
                <img src={sourceImg} alt="profile-pics" />
              ) : (
                <img
                  src={
                    "https://static.vecteezy.com/system/resources/thumbnails/002/534/006/small/social-media-chatting-online-blank-profile-picture-head-and-body-icon-people-standing-icon-grey-background-free-vector.jpg"
                  }
                  alt="profile"
                />
              )}

              <div>
                <input type="files" {...getInputProfilePicsProps()} />
                <CustomButton
                  {...getRootProfilePicsProps()}
                  customStyles={{
                    width: "220.42px",
                    height: "40px",
                    marginTop: "0",
                  }}
                  children={
                    <div className="button-sep-div">
                      <span>Upload Your Photo</span>
                      <CgProfile />
                    </div>
                  }
                />
              </div>
            </div>
            <ProfileEditInput
              id="name"
              label="Full Name"
              placeholder="Please enter your full name"
              type="text"
              value={values.name}
              onChange={handleChange("name")}
            />
            <ProfileEditInput
              id="email"
              label="Email address"
              placeholder="Enter your email address"
              type="text"
              value={values.email}
            />

            <div className="auto-complete-con">
              <CustomPhoneInput
                id="phone"
                label="Phone Number"
                type="number"
                value={values.phone}
                onChange={handleChange("phone")}
                touched={touched.phone}
                error={errors.phone}
                onBlur={(e) => handleBlur(e)}
              />
            </div>

            <div className="auto-complete-con">
              <CustomAutoComplete
                id="country"
                isClearable={false}
                label="Nationality"
                placeholder={"select country"}
                options={countries}
                initOption={countrySelectedOption}
                onChange={changeCountryHandler}
              />
            </div>

            <div className="auto-complete-con">
              <CustomAutoComplete
                id="state"
                isClearable={false}
                label="State"
                placeholder={"select state"}
                options={states}
                initOption={selectedProvinceOption}
                onChange={onChangeState}
              />
            </div>

            <div className="auto-complete-con">
              <CustomAutoComplete
                id="city"
                isClearable={false}
                label="City"
                placeholder={"select city"}
                options={cities}
                initOption={cityOption}
                onChange={onChangeCity}
              />
            </div>

            <ProfileEditInput
              id="address"
              label="Address"
              placeholder="Enter your address"
              type="text"
              value={values.address}
              onChange={handleChange("address")}
            />

            <ProfileEditInput
              id="postal_code"
              label="Postal Code"
              placeholder="Please enter your postal code"
              type="number"
              value={values.postal_code}
              onChange={handleChange("postal_code")}
            />

            <div className="auto-complete-con">
              <CustomAutoComplete
                label="Account Type"
                placeholder={"Select account type"}
                disabled
                initOption={
                  user?.account_type === "individual"
                    ? {
                        label: "Individual",
                        value: "Individual",
                      }
                    : {
                        label: "Organisaction",
                        value: "Organisaction",
                      }
                }
              />
            </div>

            <ProfileEditDate
              id="date_of_birth"
              label="Date of birth"
              type="date"
              value={values.date_of_birth}
              onChange={handleDateChange}
              icon={<CalendarIcon />}
            />

            {user?.account_type === "individual" && (
              <div className="auto-complete-con">
                <CustomAutoComplete
                  id="gender"
                  isClearable={false}
                  label="Gender"
                  placeholder={"select gender"}
                  options={genders}
                  initOption={selectedGender}
                  onChange={onChangeGender}
                />
              </div>
            )}

            <div
              className="auto-complete-con upload-file"
              {...getRootProfileCvProps()}
            >
              <input type="files" {...getInputProfileCvProps()} />
              <div>
                <FileIcon />
              </div>
              {profileCv?.name || user?.cv ? (
                <span>{profileCv?.name || user?.cv}</span>
              ) : (
                <span>Upload CV</span>
              )}
            </div>

            <ProfileEditInput
              id="about_us"
              label="How did you hear about us?"
              type="text"
              value={values.about_us}
              onChange={handleChange("about_us")}
            />
          </div>
        </div>

        <hr />

        <div className="part">
          <h2>
            2.{" "}
            {user?.account_type === "individual"
              ? "Your Career"
              : "Your Operation"}
          </h2>

          <div className="input-group">
            {user?.account_type === "individual" && (
              <div className="auto-complete-con">
                <CustomAutoComplete
                  id="division"
                  disabled
                  label="Division"
                  initOption={values.division}
                  customStyles={{
                    control: {
                      width: "100%",
                      borderRadius: "100px",
                    },
                  }}
                />
              </div>
            )}
            {user?.account_type === "individual" ? (
              <div className="inner-career-inputs">
                <div className="auto-complete-con">
                  <CustomAutoComplete
                    isClearable={false}
                    id="career_state"
                    label="Career state"
                    placeholder="Please enter career state"
                    options={secondStates}
                    initOption={selSecondProvinceOption}
                    onChange={(option) => {
                      handleChange({
                        target: {
                          name: "career_state",
                          value: option?.label,
                        },
                      });
                    }}
                  />
                </div>

                <div className="auto-complete-con">
                  <CustomAutoComplete
                    isClearable={false}
                    id="career_city"
                    label="Career city"
                    placeholder="Please enter career city"
                    options={secondCities}
                    initOption={selSecondCityOption}
                    onChange={(option) => {
                      handleChange({
                        target: {
                          name: "career_city",
                          value: option?.label,
                        },
                      });
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="inner-career-inputs">
                <div className="auto-complete-con">
                  <CustomAutoComplete
                    isClearable={false}
                    id="operation_state"
                    label="Operation state"
                    placeholder="Please enter operation state"
                    options={secondStates}
                    initOption={selSecondProvinceOption}
                    onChange={(option) => {
                      handleChange({
                        target: {
                          name: "operation_state",
                          value: option?.label,
                        },
                      });
                    }}
                  />
                </div>

                <div className="auto-complete-con">
                  <CustomAutoComplete
                    isClearable={false}
                    id="operation_city"
                    label="Operation city"
                    placeholder="Please enter opeartion city"
                    options={secondCities}
                    initOption={selSecondCityOption}
                    onChange={(option) => {
                      handleChange({
                        target: {
                          name: "operation_city",
                          value: option?.label,
                        },
                      });
                    }}
                  />
                </div>
              </div>
            )}
            {user?.account_type === "individual" ? (
              <div
                className="auto-complete-con upload-file"
                {...getRootLicenseProps()}
              >
                <input type="files" {...getInputLicenseProps()} />
                <div>
                  <FileIcon />
                </div>
                {values.license?.name || user?.license ? (
                  <span>{values.license?.name || user?.license}</span>
                ) : (
                  <span>Upload License</span>
                )}
              </div>
            ) : (
              <div className="auto-complete-con">
                <CustomAutoComplete
                  id="organisation_role"
                  label="Role"
                  isClearable={false}
                  placeholder={"Select role"}
                  initOption={values.organisation_role}
                  customStyles={{
                    control: {
                      width: "100%",
                      borderRadius: "100px",
                    },
                  }}
                  options={roles}
                  onChange={(e) => {
                    handleChangeSelectRole(e);
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <hr />

        {/* <div className="part">
          <h2>3. Marketing Preferences</h2>

          <p>
            We would like to get in touch with you with relevant information
            from time to time. Please select from the contact options below:
          </p>

          <div className="items">
            <ul>
              {items.map((item, index) => (
                <li key={index}>
                  <div className="item">
                    <span>{item}</span>
                    <label class="toggle-switch">
                      <input type="checkbox" />
                      <div class="toggle-switch-background">
                        <div class="toggle-switch-handle"></div>
                      </div>
                    </label>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="privacy">
            <p>
              Please note: You may still be contacted with emails and mobile
              messages relating to job applications you have made, or with
              relevant payroll, administration or service messages. For more
              details see our <span>Data Protection Policy</span> &{" "}
              <span>Privacy Policy</span>
            </p>
          </div>
        </div>

        <hr /> */}

        <div className="part">
          <h2>3. Skills</h2>

          <div className="input-group">
            <div className="auto-complete-con">
              <CustomAutoComplete
                isClearable={false}
                placeholder="Enter your each skill"
                initOption={skill?.label}
                options={skills}
                customStyles={{
                  control: {
                    width: "100%",
                    borderRadius: "100px",
                  },
                }}
                onChange={(e) => setSkill(e)}
              />
            </div>

            <CustomButton
              onClick={handleAddSkill}
              customStyles={{
                width: "110px",
                height: "40px",
                marginTop: "0",
                fontSize: "14px",
              }}
              title="Add Skill"
            />

            <div className="skills-lists">
              {values.skills?.length === 0 ? (
                <div>No skills added</div>
              ) : (
                values.skills?.map((i, index) => (
                  <div key={i} className="each-skill">
                    {i}
                    <span onClick={() => handleDeleteSkill(index)}>x</span>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        <CustomButton
          type="submit"
          onClick={handleSubmit}
          loading={loading}
          customStyles={{
            width: "220.42px",
            height: "40px",
            marginTop: "0",
          }}
          children={
            <div className="button-sep-div">
              <span>Update Now</span>
              <GoArrowRight />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default memo(ProfileEdit);
