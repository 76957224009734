import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./PostJob.css";
import ProfileEditInput from "../../../components/profileEditInput/ProfileEditInput";
import CustomPhoneInput from "../../../components/customPhoneInput/CustomPhoneInput";
import useCountries from "../../../hook/useCountries";
import toLower from "lodash/fp/toLower";
import {
  _currency_format,
  errorProps,
  isEmpty,
  neutralProps,
} from "../../../utils";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useTypedSelector } from "../../../hook/useTypedSelector";
import usePostJob from "../../../hook/usePostJob";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BsPlus } from "react-icons/bs";
import { RiDeleteBinLine } from "react-icons/ri";
import CustomButton from "../../../components/customButton/CustomButton";
import CustomAutoComplete from "../../../components/customAutoComplete/CustomAutoComplete";
import DashboardModal from "../../../components/dashboardModal/DashboardModal";
import DateFrameInput from "../../../components/activity/components/dateFrameInput/DateFrameInput";
import TimeFrameInput from "../../../components/activity/components/timeFrameInput/TimeFrameInput";
import usePublicDivision from "../../../hook/usePublicDivision";
import { useAuthQuery } from "../../../store/query/auth";
import { useGetSingleJobQuery } from "../../../store/query/jobs";

const PostJob = () => {
  const { user, token } = useTypedSelector((store) => store.appUser);
  const [duty, setDuty] = useState("");
  const [doc, setDoc] = useState("");
  const countries = useCountries();
  const { id } = useParams();
  const { data: job } = useGetSingleJobQuery({ id });
  const [showModal, setShowModal] = useState(false);
  const [sheetDetails, setSheetDetails] = useState({
    date_from: "",
    date_to: "",
    time_from: "",
    time_to: "",
    hour: "",
    charge: "",
  });
  const [errors, setErrors] = useState({});
  const { data: auth } = useAuthQuery({
    token: token,
    acc_type: user?.account_type,
  });

  useEffect(() => {
    if (auth?.data && auth?.data.status !== "2") {
      toast(
        <span className="toast-msg">
          {auth?.data.status_message.length
            ? auth?.data.status_message
            : "Your Account is Under Assessment, Please be patient !"}
        </span>,
        neutralProps
      );
    }
  }, [auth]);

  const {
    values,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    dirty,
    canSubmit,
    loading,
    states,
    cities,
    state,
    city,
  } = usePostJob({ job: job?.data, id, user });
  const { divisonList } = usePublicDivision();

  const countrySelectedOption = useMemo(() => {
    return countries.find((i) => toLower(i.value) === toLower("NG"));
  }, [countries]);

  const findSingleDivision =
    useMemo(() => {
      return divisonList?.find(
        (i) =>
          i.label === user?.division?.name ||
          values?.divisionObj?.name ||
          job?.data?.division?.name
      );
    }, [
      divisonList,
      user?.division?.name,
      values?.divisionObj?.name,
      job?.data?.division?.name,
    ]) || null;

  useEffect(() => {
    setFieldValue("divisionObj", findSingleDivision);
  }, [findSingleDivision, setFieldValue]);

  const onChangeSelect = useCallback(
    (option) => {
      handleChange({
        target: {
          name: "state",
          value: option,
        },
      });
      handleChange({
        target: {
          name: "city",
          value: null,
        },
      });
    },
    [handleChange]
  );

  const onChangeSelectCity = useCallback(
    (option) => {
      handleChange({
        target: {
          name: "city",
          value: option,
        },
      });
    },
    [handleChange]
  );

  const handAddResponsibility = () => {
    if (!isEmpty(duty)) {
      const newResponsiblities = [...values.responsibilities, duty];
      setFieldValue("responsibilities", newResponsiblities);
      setDuty("");
    }
  };

  const handleDelRes = (id) => {
    const updateRes = values.responsibilities.filter((_, i) => i !== id);
    setFieldValue("responsibilities", updateRes);
  };

  const handleDelReq = (id) => {
    const updatedReq = values.requirements.filter((_, i) => i !== id);
    setFieldValue("requirements", updatedReq);
  };

  const handAddRequirement = () => {
    if (!isEmpty(doc)) {
      const newRequirements = [...values.requirements, doc];
      setFieldValue("requirements", newRequirements);
      setDoc("");
    }
  };

  const check = () => {
    const dateFrom = new Date(sheetDetails.date_from);
    const dateTo = new Date(sheetDetails.date_to);
    let [hours1, minutes1] = sheetDetails.time_from.split(":").map(Number);
    let [hours2, minutes2] = sheetDetails.time_to.split(":").map(Number);

    let time1 = new Date();
    time1.setHours(hours1, minutes1, 0, 0);

    let time2 = new Date();
    time2.setHours(hours2, minutes2, 0, 0);

    const newErrors = {};

    if (dateFrom > dateTo) {
      newErrors.date_to = "End date must be greater";
    } else if (!sheetDetails.date_from.length) {
      newErrors.date_from = "Add start date";
    } else if (!sheetDetails.date_to.length) {
      newErrors.date_to = "Add end date";
    } else if (time1 > time2) {
      newErrors.time_to = "End must be greater";
    } else if (!sheetDetails.time_from.length) {
      newErrors.time_from = "Add start time";
    } else if (!sheetDetails.time_to.length) {
      newErrors.time_to = "Add end time";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const getHoursnCharge = () => {
    const dateFrom = new Date(sheetDetails.date_from);
    const dateTo = new Date(sheetDetails.date_to);

    const timeFrom = new Date(`1970-01-01T${sheetDetails.time_from}:00Z`);
    const timeTo = new Date(`1970-01-01T${sheetDetails.time_to}:00Z`);

    let diff_in_time = dateTo.getTime() - dateFrom.getTime();
    let diff_in_days = Math.round(diff_in_time / (1000 * 3600 * 24));

    let totalDays = diff_in_days + 1;
    const diffInMilliseconds = timeTo - timeFrom;

    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
    const hours = totalDays * diffInHours;
    const charge = hours * Number(values.hourly_rate);

    setSheetDetails((prev) => ({
      ...prev,
      hour: hours,
      charge: charge,
    }));

    return { hours, charge };
  };

  const totalHours = useCallback(() => {
    return values.timesheet?.reduce((accumulator, currentItem) => {
      return accumulator + Number(currentItem.hour);
    }, 0);
  }, [values?.timesheet]);

  const totalCharge = useCallback(() => {
    return values.timesheet?.reduce((accumulator, currentItem) => {
      return accumulator + Number(currentItem.charge);
    }, 0);
  }, [values?.timesheet]);

  const addTimeSheet = async () => {
    if (check()) {
      if (getHoursnCharge()) {
        const result = getHoursnCharge();
        const newTimeSheet = [
          ...values.timesheet,
          {
            start_date: sheetDetails.date_from,
            end_date: sheetDetails.date_to,
            start_time: sheetDetails.time_from,
            end_time: sheetDetails.time_to,
            hour: result?.hours,
            charge: result?.charge,
          },
        ];
        setFieldValue("timesheet", newTimeSheet);

        setSheetDetails({
          date_from: "",
          date_to: "",
          time_from: "",
          time_to: "",
          hour: "",
          charge: "",
        });
      }
    }
  };

  const handleTimeSheet = (id) => {
    const updateRes = values.timesheet.filter((_, i) => i !== id);
    setFieldValue("timesheet", updateRes);
  };

  return (
    <div className="postjob-container">
      <h4>Post a Job</h4>

      <div className="postjob-content">
        <div className="inner-box">
          <div className="first-box">
            <h5>About Company</h5>
            <p>Kindly provide your company's name and other details.</p>
          </div>
          <div className="second-box">
            <div className="inner-input-1">
              <div className="auto-complete-con">
                <ProfileEditInput
                  label="Company Name"
                  placeholder="Please enter your company name"
                  width={"100%"}
                  widthCon={"100%"}
                  type="text"
                  value={values.company_name}
                  onChange={handleChange("company_name")}
                />
              </div>
              <div className="auto-complete-con">
                <ProfileEditInput
                  label="Contact email address"
                  placeholder="Enter your email address"
                  width={"100%"}
                  widthCon={"100%"}
                  type="email"
                  value={values.contact_email}
                  onChange={handleChange("contact_email")}
                />
              </div>
            </div>
            <div className="inner-input-1">
              <div className="auto-complete-con">
                <CustomPhoneInput
                  label="Phone Number"
                  width={"100%"}
                  widthCon={"100%"}
                  value={values.phone}
                  onChange={handleChange("phone")}
                />
              </div>

              <div className="auto-complete-con">
                <CustomAutoComplete
                  isClearable={false}
                  label="Nationality"
                  placeholder={"select_country"}
                  otherValue={values.nationality}
                  options={countries}
                  initOption={countrySelectedOption}
                  value={values.nationality}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="inner-box">
          <div className="first-box">
            <h5>About Job</h5>
            <p>
              Just a little secret; jobs with better offer get more engagement.
              Don't forget to make your offer enticing 😃{" "}
            </p>
          </div>
          <div className="second-box">
            <div className="inner-input-1">
              <div className="auto-complete-con">
                <ProfileEditInput
                  label="Job Title"
                  placeholder={"Select job title"}
                  width={"100%"}
                  widthCon={"100%"}
                  value={values.title}
                  onChange={handleChange("title")}
                />
              </div>

              <div className="auto-complete-con">
                <CustomAutoComplete
                  isClearable={false}
                  label="Division"
                  placeholder="Select division"
                  otherValue={values.divisionObj?.value}
                  options={
                    user?.account_type === "organisation" ? divisonList : []
                  }
                  initOption={values.divisionObj}
                  onChange={(option) => {
                    handleChange({
                      target: {
                        name: "divisionObj",
                        value: option,
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className="inner-desc">
              <label className="desc-text">Description</label>
              <textarea
                cols="60"
                value={values.description}
                onChange={handleChange("description")}
                placeholder="Write down about Company / Job"
              />
            </div>
            <div className="inner-input-1">
              <div className="auto-complete-con">
                <CustomAutoComplete
                  isClearable={false}
                  label="State"
                  placeholder={"select state"}
                  options={states}
                  initOption={state}
                  onChange={onChangeSelect}
                />
              </div>
              <div className="auto-complete-con">
                <CustomAutoComplete
                  isClearable={false}
                  label="City"
                  placeholder={"select city"}
                  options={cities}
                  //otherValue={values.city}
                  initOption={city}
                  onChange={onChangeSelectCity}
                />
              </div>
            </div>

            <div className="inner-input-1">
              <div className="auto-complete-con">
                <ProfileEditInput
                  label="Address"
                  placeholder={"Write Address"}
                  width={"100%"}
                  widthCon={"100%"}
                  value={values.address}
                  onChange={handleChange("address")}
                />
              </div>
              <div className="auto-complete-con">
                <ProfileEditInput
                  label="Hourly Rate"
                  placeholder="N0.00"
                  width={"100%"}
                  widthCon={"100%"}
                  type="number"
                  value={values.hourly_rate}
                  onChange={handleChange("hourly_rate")}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="inner-box">
          <div className="first-box">
            <h5>Responsibilities</h5>
            <p>
              Outline clear responsibilities to get the best off your
              placeholder.{" "}
            </p>
          </div>

          <div className="second-box">
            <label>Describe the responsibilities</label>
            <textarea
              placeholder="Type responsibility"
              rows={3}
              value={duty}
              onChange={(e) => setDuty(e.target.value)}
            />
            <button onClick={handAddResponsibility}>ADD</button>

            <div className="min-box">
              {values?.responsibilities?.length === 0 ? (
                <div className="no-res">
                  <p>Add Responsibilities</p>
                </div>
              ) : (
                values?.responsibilities?.map((res, i) => (
                  <div key={i} className="res-item">
                    <div className="res-item-content">
                      <div className="bg-dot">
                        <div className="dot-num" />
                      </div>
                      <div className="res-item-words">
                        <p>{res}</p>
                      </div>
                    </div>

                    <div className="res-bg-icon">
                      <RiDeleteBin6Line
                        className="icon-btn-del"
                        onClick={() => handleDelRes(i)}
                      />
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        <div className="inner-box">
          <div className="first-box">
            <h5>Requirements</h5>
            <p>State each Requirements for applicants. </p>
          </div>

          <div className="second-box">
            <label>Requirements</label>
            <textarea
              placeholder="Type requirement"
              value={doc}
              onChange={(e) => setDoc(e.target.value)}
            />
            <button onClick={handAddRequirement}>ADD</button>

            <div className="min-box">
              {values?.requirements?.length === 0 ? (
                <div className="no-res">
                  <p>Add Requirements</p>
                </div>
              ) : (
                values?.requirements?.map((req, i) => (
                  <div key={i} className="res-item">
                    <div className="res-item-content">
                      <div className="bg-dot">
                        <div className="dot-num" />
                      </div>
                      <div className="res-item-words">
                        <p>{req}</p>
                      </div>
                    </div>

                    <div className="res-bg-icon">
                      <RiDeleteBin6Line
                        className="icon-btn-del"
                        onClick={() => handleDelReq(i)}
                      />
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        <div className="btn-bg">
          <div className="btn">
            <CustomButton
              onClick={() => {
                if (values.hourly_rate.length === 0) {
                  toast.error("please add hourly rate", errorProps);
                } else {
                  setShowModal(true);
                }
              }}
              title="Fill Timesheet"
              customStyles={{
                width: "100%",
                borderRadius: "5px",
                backgroundColor: "#245293",
                color: "#fff",
              }}
            />
          </div>
          <div className="btn">
            <CustomButton
              title="Reset"
              type="btn"
              disable={!dirty}
              onClick={handleReset}
              background={"red"}
              border={"red"}
              customStyles={{
                width: "100%",
                borderRadius: "5px",
                color: "#fff",
              }}
            />
          </div>

          <div className="btn">
            <CustomButton
              onClick={() => {
                if (canSubmit) handleSubmit();
              }}
              title={id ? "Update" : "Publish"}
              type="submit"
              loading={loading}
              disable={!canSubmit}
              customStyles={{
                width: "100%",
                borderRadius: "5px",
                backgroundColor: "#245293",
                color: "#fff",
              }}
            />
          </div>
        </div>
      </div>

      <DashboardModal isOpen={showModal}>
        <div className="dashboard-modal">
          <h3>Locum Timesheet</h3>

          <div className="box-timesheet">
            <div className="box-timesheet-input-cover">
              <DateFrameInput
                label="Start and End Date"
                values={sheetDetails}
                setValues={setSheetDetails}
                error={errors}
              />

              <TimeFrameInput
                label="Start and End Time"
                values={sheetDetails}
                setValues={setSheetDetails}
                error={errors}
              />

              <div className="add-rows-btn">
                <CustomButton
                  variant="outline"
                  onClick={addTimeSheet}
                  title={
                    <div style={btnStyled}>
                      Add Row{" "}
                      <BsPlus style={{ fontSize: "18px", fontWeight: 600 }} />
                    </div>
                  }
                  customStyles={{
                    borderRadius: "5px",
                  }}
                />
              </div>
            </div>

            {values.timesheet?.length > 0 && (
              <div className="candidates-result">
                <div className="candidates-result-headings">
                  <span>Start Date</span>
                  <span>End Date</span>
                  <span>Start Time</span>
                  <span>End Time</span>
                  <span>Hours</span>
                  <span>Charges</span>
                  <span>Action</span>
                </div>
                <div className="candidates-result-box">
                  {values.timesheet?.map((item, i) => (
                    <div key={i} className="single-candidate">
                      <div className="single">
                        <span className="mobile-time">Start Date:</span>
                        <span>{item.start_date}</span>
                      </div>
                      <div className="single">
                        <span className="mobile-time">End Date:</span>
                        <span>{item.end_date}</span>
                      </div>
                      <div className="single">
                        <span className="mobile-time">Start Time:</span>
                        <span>{item.start_time}</span>
                      </div>
                      <div className="single">
                        <span className="mobile-time">End Time:</span>
                        <span>{item.end_time}</span>
                      </div>
                      <div className="single">
                        <span className="mobile-time">Hours:</span>
                        <span>{item.hour}</span>
                      </div>
                      <div className="single">
                        <span className="mobile-time">Charges:</span>
                        <span>
                          {_currency_format(Number(item.charge), "NGN")}
                        </span>
                      </div>
                      <div className="single">
                        <span className="mobile-time">Action:</span>
                        <RiDeleteBinLine
                          style={{ cursor: "pointer" }}
                          color="red"
                          size="20"
                          onClick={() => handleTimeSheet(i)}
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: 700,
                        color: "#465174",
                      }}
                    >
                      Total Hours:
                    </span>
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: 800,
                      }}
                    >
                      {totalHours()}
                    </span>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: 700,
                        color: "#465174",
                      }}
                    >
                      Total Charge:
                    </span>
                    <span
                      style={{
                        fontSize: "15px",
                        fontWeight: 800,
                      }}
                    >
                      {_currency_format(Number(totalCharge()), "NGN")}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="btn-bg">
            <div className="btn">
              <CustomButton
                title="Close"
                type="btn"
                onClick={() => setShowModal(false)}
                background={"red"}
                border={"red"}
                customStyles={{
                  width: "100%",
                  borderRadius: "5px",
                  color: "#fff",
                }}
              />
            </div>

            <div className="btn">
              <CustomButton
                onClick={() => setShowModal(false)}
                disable={!values.timesheet.length}
                title="Finish"
                customStyles={{
                  width: "100%",
                  borderRadius: "5px",
                  backgroundColor: "#245293",
                  color: "#fff",
                }}
              />
            </div>
          </div>
        </div>
      </DashboardModal>
    </div>
  );
};

export default PostJob;

const btnStyled = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  padding: "2px 10px",
};
